import React, { Component, Fragment } from 'react';
import {banImg, DescriptionDiv, itBox, ItemWrapperA, itImg, itMargin, midBox, TitDiv} from './style'
import {getBannerData} from "../../Main/service";
import {parseRespondResult} from "../../../utils/request";
import {getPSList} from "../service";
import {Pagination, Spin} from "antd";
import '../../../style.js';

/**
 * @Description: 产品&解决方案 content
 * @Author: ZQ
 * @Date: 2022-09-14 14:10
 */
class ProductItem extends Component {
    state = {
      loading: true,
      current: 1,
      pageNum: 1,
      pageSize: 6,
      total: 0,
      bannerUrl: '',
      dataList: [],
    }

    async componentDidMount() {
      window.scrollTo(0, 0);
      await this.getInitData();
    }

    // 初始化数据
    getInitData = async () => {
      this.setState({ loading: true });
      try {
        await this.getInitBanner();
        await this.getPageData(this.state.pageNum);
      }finally {
        this.setState({ loading: false });
      }
    }


    // 获取分页数据
    getPageData = async (page) => {
      const { pageSize } = this.state;
      const res = await getPSList({ pageNum: page, pageSize });
      if(parseRespondResult(res)) {
        this.setState({
          pageNum: page,
          dataList: res?.data?.dataList,
          total: res?.data?.totalCount,
        });
      }
    }

    // 初始化 banner数据
    getInitBanner = async () => {
      const res = await getBannerData({ moduleType: 3 });
      if(parseRespondResult(res)) {
        this.setState({
          bannerUrl: res?.data?.files[0]?.url,
        });
      }
    }

    // 分页 change 事件
    onChange = async (page) => {
      this.setState({ loading: true });
      try {
        await this.getPageData(page);
      }finally {
        this.setState({ loading: false });
      }
    }

    render() {
      const { dataList, loading, total, bannerUrl, pageSize, pageNum } = this.state;
      const { history } = this.props;

      const goProductDetail = (id) => {
        history.push({
          pathname: `/productDetail`,
          search: id,
        })
      }

      return (
          <Fragment>
            <Spin spinning={loading}>
              <ItemWrapperA>
                <img src={bannerUrl} style={banImg} alt=''/>
              </ItemWrapperA>

              <div style={midBox}>
                {
                  dataList?.length ? dataList.map((it, idx) =>
                    <div style={itBox} key={idx} onClick={() => goProductDetail(it?.psId)}>
                      <div style={itMargin}>
                        <img src={it?.picUrl} alt='' style={itImg} />
                        <TitDiv>{it?.psName}</TitDiv>
                        <DescriptionDiv>{it?.psDescription}</DescriptionDiv>
                      </div>
                    </div>
                  ) : ''
                }
                {
                  total > 0 &&
                  <div className='paginationBox'>
                    <Pagination
                      className='ant-pagination'
                      pageSize={pageSize}
                      onChange={(page) => this.onChange(page)}
                      showQuickJumper={false}
                      showSizeChanger={false}
                      current={pageNum}
                      total={total}
                    />
                  </div>
                }
              </div>

            </Spin>
          </Fragment>
      );
    }
}

export default ProductItem;
