import React, { Component, Fragment } from 'react';
import { css } from 'aphrodite';
import {
  ItemWrapperB, ItemBHoverTitle, ItemBMore, ItemBHoverTxt, ItemB, ItemBHover, ItemWrapperC, ItemC, ItemCLeft,
  ItemCRight, ItemBPic, ItemBFourPic1, ItemMore, ContentText, more, moreImg, leftImg, CompanyTitDiv,
} from './style'
import {styles} from "../../../utils/common";

/**
 * @Description: 首页 content
 * @Author: ZQ
 * @Date: 2022-09-14 10:10
 */
class MainItem extends Component {
  state = {
    bShowCont: 0,
    cShowCont: 0,
    bShow: false,
    cShow: false,
  }

    componentDidMount() {
        window.scrollTo(0, 0);
        if(!this.state.bShowCont && !this.state.cShowCont) {
          window.addEventListener('scroll', this.scroll);
        }
    }

    componentWillUnmount(){
      window.removeEventListener('scroll', this.scroll);
    }

    // 滚动事件
    scroll = () => {
      const top = document.documentElement.scrollTop || document.body.scrollTop;
      const cbox = document.getElementById("c")
      const bbox = document.getElementById("b");
      if(window.screen.availHeight + top > bbox.offsetTop) {
        this.setState({
          bShow: true,
          bShowCont: 1,
        })
      }

      if(window.screen.availHeight + top > (cbox.offsetTop - 240)) {
        this.setState({
          cShow: true,
          cShowCont: 1,
        })
      }
    }

    goToDetail = (id) => {
      this.props.history.push({
        pathname: `/productDetail`,
        search: id,
      });
    }

  // 鼠标 移入  移出
  handleMouse(index, bol) {
    const { topList } =  this.props;
    const list = topList;
    topList[index].isMouseMoves = bol;
    this.setState({
      topList: list,
    })
  }

  render() {
      const { bShow, cShow } = this.state;
      const { topList, info } = this.props;

      return (
          <Fragment>
            <ItemWrapperB id='b'>
              <ItemB className={bShow && css(styles.magicTxt)}>产品&解决方案</ItemB>
              <ItemBPic className={bShow && css(styles.magicImg)}>
                {
                  topList?.length ? topList.map((it, idx) =>
                    <ItemBFourPic1
                      key={idx}
                      onMouseEnter={() => this.handleMouse(idx,true)}
                      onMouseLeave={() => this.handleMouse(idx,false)}
                      style={{ background: `url(${it?.picUrl}) no-repeat`, backgroundSize: '100% 100%' }}
                    >
                      {
                        it?.isMouseMoves &&
                          <ItemBHover className={css(styles.magicHover)} onClick={() => this.goToDetail(it?.psId)}>
                            <ItemBHoverTitle>{ it?.psName }</ItemBHoverTitle>
                            <ItemBHoverTxt>{it?.psDescription}</ItemBHoverTxt>
                            <ItemBMore>了解更多</ItemBMore>
                          </ItemBHover>
                          // :
                          // <div style={itTit}>{ it?.psName && it?.psName.length > 5 ? it?.psName.substring(0, 5) : it?.psName }</div>
                      }
                    </ItemBFourPic1>
                  ) : ''
                }
              </ItemBPic>
              <ItemMore onClick={() => this.props.history.push('/product')}>查看更多&nbsp;&nbsp;
                <img style={moreImg} src={require('../../../statics/img/more.png')} alt='' />
              </ItemMore>
            </ItemWrapperB>

            <ItemWrapperC id='c'>
              <ItemC className={cShow && css(styles.magic)}>
                  <ItemCLeft>
                      <img style={leftImg} src={info?.enterpriseFile} alt='' />
                  </ItemCLeft>
                  <ItemCRight>
                      <CompanyTitDiv>公司简介</CompanyTitDiv>
                      <ContentText>{info?.description}</ContentText>
                      <div onClick={() => this.props.history.push('/introduce')} style={more}>查看更多&nbsp;&nbsp;
                        <img style={moreImg} src={require('../../../statics/img/more.png')} alt='' />
                      </div>
                  </ItemCRight>
              </ItemC>
            </ItemWrapperC>
          </Fragment>
      );
  }
}

export default MainItem;
