import React, { Component } from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import VideoOrImgBg from './components/VideoOrImgBg.jsx';
import MainItem from './components/MainItem.jsx';
import {getBannerData, getEnterpriseData, getTopData} from "./service";
import {parseRespondResult} from "../../utils/request";
import {Layout, Spin} from "antd";

/**
 * @Description: 首页
 * @Author: ZQ
 * @Date: 2022-09-14 10:10
 */
class Index extends Component {

  state = {
    loading: true,
    opacity: 0,
    nowPage: 'main',
    isShow: false,
    // banner
    bannerInfo: {
      url: undefined,
      bannerType: undefined,
    },
    // 热门
    topList: [],

    // 公司简介
    enterpriseInfo: {
      description: '',
      enterpriseFile: '',
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.initWindowListener();
    await this.getInitData();
  }

  // 初始化 监听window事件
  initWindowListener = () => {
    window.addEventListener('scroll', () => {
      let offsetY = window.scrollY
      this.setState({
        opacity: offsetY < 300 ? offsetY / 300 : 1,
        isShow: offsetY < 300,
      })
    });
  }

  // 初始化数据
  getInitData = async () => {
    this.setState({ loading: true });
    try {
      const res = await Promise.all([
        getBannerData({ moduleType: 1 }),
        getTopData(),
        getEnterpriseData(),
      ]);

      this.setState({
        bannerInfo: parseRespondResult(res[0]) && {
          url: res[0]?.data?.files[0]?.url,
          bannerType: res[0]?.data?.bannerType,
        },

        topList: parseRespondResult(res[1]) && res[1]?.data && res[1]?.data.length > 4 ? res[1]?.data.slice(0, 4) : res[1]?.data,

        enterpriseInfo: parseRespondResult(res[2]) && {
          description: res[2]?.data?.description,
          enterpriseFile: res[2]?.data?.enterpriseFile,
        }
      });
    }finally {
      this.setState({ loading: false });
    }
  }

  render() {

    const { loading, opacity, nowPage, isShow, bannerInfo, topList, enterpriseInfo } = this.state;
    const { history } = this.props;

    return (
      <Layout style={{ overflowX: 'hidden', }}>
        <Header opacity={opacity} nowPage={nowPage} history={history}/>
        <Layout>
            <Spin spinning={loading}>
              <VideoOrImgBg
                bannerInfo={bannerInfo}
              />
              <MainItem
                isShow={isShow}
                history={history}
                topList={topList}
                info={enterpriseInfo}
              />
            </Spin>
        </Layout>
        <Footer />
      </Layout>
    );
  }
}

export default Index;
