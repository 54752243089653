import React, { Component } from 'react';
import {
  FooterWrapper, Container, FooterLogo, NameAndCopyright, TelAndAddress, QrcodeLeftPic, QrcodeMidPic, QrcodeRightPic,
  QrcodeDiv, FooterContent, NameAndCopyTopText, TelAndAddressText
} from './style'
import {getJoinWeData} from "../../pages/JoinWe/service";
import {parseRespondResult} from "../../utils/request";

class Footer extends Component {
  state = {
    info: {},
  }

  async componentDidMount() {
    await this.getInitData();
  }

  getInitData = async () => {
    const res = await getJoinWeData();
    if(parseRespondResult(res)) {
      this.setState({
        info: res?.data,
      })
    }
  }

    render() {
    const { info } = this.state;
      const style = {
        display: 'flex',
        alignItems: 'center',
      };

      const imgStyle = {
        transform: 'translateY(-2px)',
        width:'17px',
        height: '17px',
        marginRight: '6px',
      };

      const picText = {
        textAlign: 'center',
        width: '140px',
        marginTop: '18px',
        height: '42px',
        color: '#fff',
      };

      const marBot = {
        marginBottom: '14px'
      };

        return (
            <FooterWrapper>
                <Container>
                    <FooterLogo/>
                    <FooterContent>
                      <NameAndCopyright>
                        <NameAndCopyTopText>
                          <div>河南云数聚网络科技有限公司</div>
                          <div style={style}>
                            <img src={require('../../statics/img/sign.png')} style={imgStyle} alt=''/>
                            <a style={{textDecoration: 'none', color: '#fff'}}
                               href="https://beian.miit.gov.cn/" target="_blank"
                               rel="noopener noreferrer">豫ICP备19001401号-1</a>
                          </div>
                        </NameAndCopyTopText>
                        {`Copyright © 2018-${new Date().getFullYear()} Hncdcenter Co.,Ltd, All Rights Reserved.`}
                      </NameAndCopyright>
                      <TelAndAddress>
                        <TelAndAddressText>
                          <div>联系电话：{info?.contactNumber || ''}</div>
                          <div>合作电话：{info?.cooperativeTelephone || ''}</div>
                        </TelAndAddressText>
                        <div style={{ width: '100%' }}>公司地址：{info?.enterpriseAddress || ''}</div>
                      </TelAndAddress>
                    </FooterContent>
                    <QrcodeDiv>
                      <div>
                        <QrcodeLeftPic />
                        <div style={picText}>
                          <div style={marBot}>扫一扫关注</div>
                          <div>豫信电科公众号</div>
                        </div>
                      </div>
                      <div>
                        <QrcodeMidPic/>
                        <div style={picText}>
                          <div style={marBot}>扫一扫关注</div>
                          <div>南威公众号</div>
                        </div>
                      </div>
                      <div>
                        <QrcodeRightPic/>
                        <div style={picText}>
                          <div style={marBot}>扫一扫关注</div>
                          <div>云数聚公众号</div>
                        </div>
                      </div>
                    </QrcodeDiv>
                </Container>
            </FooterWrapper>
        )
    }
}

export default Footer
