import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import routes from "./model/router";

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    this.bodyScale();
  }

  myBrowser = () => {
    const userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    const isOpera = userAgent.indexOf("Opera") > -1;
    if (isOpera) {
      return "Opera"
    }; //判断是否Opera浏览器
    if (userAgent.indexOf("Firefox") > -1) {
      return "FF";
    } //判断是否Firefox浏览器
    if (userAgent.indexOf("Chrome") > -1){
      return "Chrome";
    }
    if (userAgent.indexOf("Safari") > -1) {
      return "Safari";
    } //判断是否Safari浏览器
    if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {
      return "IE";
    }; //判断是否IE浏览器
  }

  // 计算body宽度 等比例缩放
  bodyScale = () => {
    if(this.myBrowser() === 'IE' ||  this.myBrowser() === 'Chrome') {
      const deviceWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const scale = deviceWidth / 1840;
      document.body.style.minWidth = '1340px';
      document.body.style.zoom = scale;
    }
    if(this.myBrowser() === 'FF') {
      document.body.style.minWidth = '1920px';
    }
  }

render() {
    return (
      <Router>
        <div>
          {
            routes.map((v, i) => {
              if (v.exact) {
                return <Route key={i} exact path={v.path} render={props => (
                  <v.component {...props} routes={v.children} />)}></Route>
              } else {
                return <Route key={i} path={v.path} render={props => (
                  <v.component {...props} routes={v.children} />)}></Route>
              }
            })
          }
        </div>
      </Router>
    )
  }
}

export default App;
