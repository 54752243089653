import styled from 'styled-components'

export const ItemWrapperA = styled.div`
    width:100%;
    height:560px;
    margin-top:80px;
`

export const ItemWrapperContent = styled.div`
    width:100%;
    height:540px;
    padding-top:90px;
`

export const ItemC = styled.div`
    width:1280px;
    height:100%;
    margin:0 auto;
    display:flex;
    flex-direction:row;
    align-items:center;
`

export const ItemTxt = styled.div`
   margin-top:24px
`




export const banImg = {
  width:'100%',
  height: '100%',
};

export const midBox = {
  width: '1280px',
  margin: '30px auto 87px auto',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  flexFlow: 'row wrap',
  fontFamily: 'SourceHanSansCN-Light',
};

export const itBox = {
  width: '610px',
  margin: '15px 15px',
  height: '500px',
  background: '#F5F5F5',
  cursor: 'pointer',
};

export const itMargin = { margin: '25px 25px' };

export const itImg = { width: '100%', height: '320px' };

export const TitDiv = styled.div`
  margin-top: 26px;
  width: 100%;
  height: 24px;
  font-size: 24px;
  color: #000;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const DescriptionDiv = styled.div`
    height: 75px;
    font-size: 18px;
    color: #000;
    letter-spacing: 2px;
    line-height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: justify;
`;



export const detailBox = { marginTop: '80px', };

export const detailTitBox = { width: '100%', background: '#F2F3F5' };


export const tit = {
  width: '1360px',
  margin: 'auto',
  height: '64px',
  lineHeight: '64px',
  color: '#666',
  letterSpacing: '-0.05px',
  fontSize: '20px',
};

export const pointer = { cursor: 'pointer'};


export const detailContentBox = {
  width: '1280px',
  margin: 'auto',
  padding: '50px 0 130px 0',
  fontFamily: 'SourceHanSansCN-Light',
  color: '#333333',
};

export const contentTit = {
  fontSize: '48px',
  letterSpacing: '-0.13px',
  lineHeight: '72px',
  fontWeight: '200',
};

export const oneTit = {
  width: '100%',
  height: '22px',
  fontSize: '22px',
  marginTop: '10px',
  letterSpacing: '2px',
  fontWeight: '500',
  fontFamily: 'SourceHanSansCN-Medium',
  color: '#333333',
};

export const description = {
  width: '100%',
  fontSize: '18px',
  marginTop: '20px',
  letterSpacing: '4px',
  lineHeight: '30px',
  fontWeight: '200',
  fontFamily: 'SourceHanSansCN-Light',
  color: '#333333',
  textAlign: 'justify',
};

export const twoTit = {
  width: '100%',
  height: '22px',
  marginTop: '24px',
  fontSize: '22px',
  letterSpacing: '2px',
  fontWeight: '500',
  fontFamily: 'SourceHanSansCN-Medium',
  color: '#333333',
};

export const content = {
  fontFamily: 'SourceHanSansCN-Light',
  fontSize: '18px',
  color: '#333333',
  letterSpacing: '4px',
  fontWeight: '200',
  width: '100%',
  marginTop: '20px',
  lineHeight: '30px',
};








