import React, { Component } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import NewsItem from './components/NewsItem.jsx';
import {Layout} from "antd";

/**
 * @Description: 新闻动态
 * @Author: ZQ
 * @Date: 2022-09-14 14:10
 */
class Index extends Component {

  state = {
    opacity: 1,
    nowPage:'news',
  }

  render() {
    const { opacity, nowPage } = this.state;
    const { history } = this.props;

    return (
      <Layout>
        <Header opacity={opacity} nowPage={nowPage} history={history}/>
        <NewsItem history={history} />
        <Footer />
      </Layout>
    );
  }
}

export default Index;
