import React, { Component } from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import ProductItem from "./components/ProductItem";
import {Layout} from "antd";

/**
 * @Description: 产品&解决方案
 * @Author: ZQ
 * @Date: 2022-09-15 09:10
 */
class Index extends Component {

  state = {
    opacity: 1,
    nowPage:'product',
  }

  componentDidMount() {
  }

  render() {
    const { opacity, nowPage  } = this.state;
    const { history } = this.props;
    return (
      <Layout>
        <Header opacity={opacity} nowPage={nowPage} history={history}/>
        <ProductItem
          history={history}
        />
        <Footer />
      </Layout>
    );
  }
}

export default Index;
