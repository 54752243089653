import styled from 'styled-components'

export const ItemWrapperA = styled.div`
    width:100%;
    height:560px;
    margin-top:80px;
`



export const banImg = {width:'100%', height: '100%'};

export const midBox = {
  width: '1280px',
  margin: '30px auto 87px auto',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  flexFlow: 'row wrap',
  fontFamily: 'SourceHanSansCN-Light',
};

export const itBox = {  width: '394px', margin: '15px 15px', cursor: 'pointer' };

export const itImg = { width: '100%', height: '270px' };

export const padd = { padding: '0 12px' };

export const TitDiv = styled.div`
  margin-top: 26px;
  width: 100%;
  height: 66px;
  font-size: 24px;
  color: #333333;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: justify;
`;

export const DescriptionDiv = styled.div`
    margin-top: 16px;
    width: 100%;
    height: 65px;
    font-size: 18px;
    color: #000000;
    letter-spacing: 2px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: justify;
`;

export const itTime = {
  width: '100%',
  height: '16px',
  fontSize: '16px',
  color: '#333',
  margin: '20px 0',
  letterSpacing: '1px',
};

export const box = { marginTop: '80px', fontFamily: 'SourceHanSansCN-Light', };

export const backTipBox = { width: '100%', background: '#F2F3F5', };

export const backTip = {
  width: '1360px',
  margin: 'auto',
  height: '64px',
  lineHeight: '64px',
  letterSpacing: '-0.05px',
  color: '#666',
  fontSize: '20px',

};

export const pointer = { cursor: 'pointer' };

export const contBox = { width: '1280px', margin: 'auto', padding: '50px 0 130px 0', };

export const contTit = {
  width: '1280px',
  fontSize: '48px',
  margin: 'auto',
  color: '#333333',
  letterSpacing: '-0.13px',
  lineHeight: '72px',
  fontWeight: '200',
  fontFamily: 'SourceHanSansCN-Light',
};

export const contTime = {
  fontSize: '18px',
  height: '18px',
  color: '#999999',
  letterSpacing: '-0.13px',
  margin: '30px 0 17px 0',
  fontWeight: '200',
};

export const diver = {
  width: '1340px',
  height: '1px',
  borderBottom: '0.5px solid rgba(151,151,151,1)',
  margin: '0 -30px',
};

export const description = {
  width: '100%',
  fontSize: '18px',
  color: '#333333',
  letterSpacing: '4px',
  lineHeight: '29px',
  fontWeight: '200',
  marginTop: '36px',
  textAlign: 'justify',
  fontFamily: 'SourceHanSansCN-Light',
};

export const twoTit = {
  width: '100%',
  height: '22px',
  marginTop: '24px',
  fontSize: '22px',
  letterSpacing: '2px',
  fontWeight: '500',
  fontFamily: 'SourceHanSansCN-Medium',
  color: '#333333',
};

export const cont = {
  fontFamily: 'SourceHanSansCN-Light',
  fontSize: '18px',
  color: '#333333',
  letterSpacing: '4px',
  fontWeight: '200',
  marginTop: '30px',
  width: '100%',
  lineHeight: '30px',
  };
