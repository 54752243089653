import styled from 'styled-components';
import footerLogo from '../../statics/img/footer_logo.png';
import leftQRCodePic from '../../statics/img/left_QR_code.png';
import midQRCodePic from '../../statics/img/mid_QR_code.png';
import rightQRCodePic from '../../statics/img/right_QR_code.png';

export const FooterWrapper = styled.div`
    height: 378px;
    background: #2E313D;
`
export const Container = styled.div`
    width:1280px;
    height:100%;
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    align-items:center;
    margin:0 auto;
`

export const FooterLogo = styled.div`
    flex: 0 0 150px;
    height: 150px;
    background:url(${footerLogo}) no-repeat;
    background-size: 148px 146px;
    margin-right: 26px;
    cursor: pointer;
`

export const FooterContent = styled.div`
  height: 214px;
  width: 620px;
  margin-right: 32px;
  font-family: SourceHanSansCN-Normal;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0.19px;
  line-height: 24px;
  font-weight: 400;
`

export const NameAndCopyright = styled.div`
    flex:0 0 383px;
    width: 580px;
    margin-bottom: 42px;
    padding: 12px 0;
`

export const NameAndCopyTopText = styled.div`
  width: 520px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px
`

export const TelAndAddress = styled.div`
    flex:0 0 460px;
    width: 620px;
    padding: 12px 0;
`

export const TelAndAddressText = styled.div`
  width: 570px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px
`

export const QrcodeDiv = styled.div`
  display: flex;
  justify-content: space-between;
  height: 214px;
  width: 520px;
  padding-top: 20px;
  letter-spacing: 1px;
  font-size: 18px;
`

export const QrcodeLeftPic = styled.div`
    height:140px;
    width: 140px;
    background:url(${leftQRCodePic}) no-repeat;
      background-size: 100% 100%;
    margin-right: 20px;
`
export const QrcodeMidPic = styled.div`
    height:140px;
    width: 140px;
    background:url(${midQRCodePic}) no-repeat;
      background-size: 100% 100%;
    margin-right: 20px;
`
export const QrcodeRightPic = styled.div`
    height:140px;
    width: 140px;
    background:url(${rightQRCodePic}) no-repeat;
      background-size: 100% 100%;
`






