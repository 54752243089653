import request from '../../utils/request.jsx';


// 官网分页 新闻 页面
export const getNewsList = (params) => {
  return request('/news/select/front', params);
}

// 根据id查询 新闻 详情
export const getByIdNews = (params) => {
  return request(`/news/select/one`, params);
}

