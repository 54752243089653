import request from '../../utils/request.jsx';


// 官网分页 产品&解决方案 页面
export const getPSList = (params) => {
  return request('/ps/select/front', params);
}

// 根据id查询 产品&解决方案 详情
export const getByIdPS = (params) => {
  return request(`/ps/select/one`, params);
}

