import styled from 'styled-components'

export const ItemWrapperA = styled.div`
    width:100%;
    height:560px;
    margin-top:80px;
`



export const topBan = {
  width:'100%',
  height: '100%',
};

export const midBox = {
  width: '1280px',
  margin: '30px auto 87px auto',
  fontFamily: 'SourceHanSansCN-Light',
  fontWeight: '200',
};

export const midBoxTit = {
  width: '100%',
  textAlign: 'left',
  marginBottom: '99px',
};

export const chinese = {
  height: '30px',
  fontSize: '30px',
  color: '#333333',
  letterSpacing: '0.25px',
  fontWeight: '500',
};

export const english = {
  marginTop: '28px',
  height: '30px',
  fontSize: '30px',
  color: '#CAD2E1',
  letterSpacing: '0.25px',
  fontWeight: '400',
};

export const midBoxInfo = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '99px',
  fontFamily: 'SourceHanSansCN-Light',
  // fontWeight: '200',
};

export const img = {
  width: '112px',
  height: '112px',
};

export const tip = {
  width: '100%',
  height: '22px',
  fontSize: '22px',
  color: '#333333',
  letterSpacing: '0.18px',
  fontWeight: 'bold',
  marginTop: '27px',
};

export const text = {
  marginTop: '18px',
  width: '100%',
  height: '16px',
  fontSize: '18px',
  color: '#333',
  letterSpacing: '0.13px',
  fontFamily: 'SourceHanSansCN-Light',
  fontWeight: '200',
};

export const btmMap = { width: '100%', height: '600px', };
