import axios from "axios";
import { message } from 'antd';
import md5 from 'js-md5';

// 地址头
// const baseUrl = 'http://192.168.5.252:8210/hncdcenter';
// const baseUrl = 'http://123.60.89.231:80/hncdcenter';
const baseUrl = 'https://www.hncdcenter.com/hncdcenter';
// const baseUrl = 'http://123.60.89.231:8211/hncdcenter/';
// const baseUrl = 'http://192.168.5.99:8514/hncdcenter';
// const baseUrl = 'http://127.0.0.1:8211/hncdcenter';


// 随机生成 32位 字符串
const randomString = () => {
  const t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const strLen = t.length;
  let n = "";
  for (let i = 0; i < 32; i++) {
    n += t.charAt(Math.floor(Math.random() * strLen))
  };
  return n
}

// 获取当前时间的时间戳
const curTime = new Date().getTime();

/**
 * @Description: 对象key值进行排序 abc
 * @Author: ZQ
 * @Date: 2022-09-22 17:10
 */
const objKeySort = (arr) => {
  const newKey = Object.keys(arr).sort();
  const newObj = {};
  for(let i = 0; i < newKey.length; i++) {
    newObj[newKey[i]] = arr[newKey[i]];
  }
  return newObj;
}

/**
 * @Description: 对象 转成 接口参数格式 （ key=value&key=value .... ）
 * @Author: ZQ
 * @Date: 2022-09-22 17:10
 */
const objConvertParamStr = (keyArr, valArr) => {
  let str = '';
  for (let i = 0; i < keyArr.length; i++){
    for (let j = i; j < valArr.length; j++){
      if(j === valArr.length - 1) {
        str += keyArr[i] + '=' + valArr[j];
        break;
      } else {
        str += keyArr[i] + '=' + valArr[j] + '&';
        break;
      }
    }
  }
  return str;
}

/**
 * @Description: request 请求方法
 * @Author: ZQ
 * @param: url/路径  params/参数  method/接口类型
 * @Date: 2022-09-16 10:10
 */
const request = (url, params, method = "GET") => {
  const paramsObj = {
    ...params,
    timestamp: curTime,
    nonce: randomString(),
  }
  // 排序
  const newObj = objKeySort(paramsObj);
  // 转str
  const str = objConvertParamStr(Object.keys(newObj), Object.values(newObj));
  // 加密
  const sign = md5(str);

  return new Promise((resolve, reject) => {
    if (method === "GET" || method === "DELETE") {
      axios({
        url: `${baseUrl}${url}`,
        params: {
          ...paramsObj,
          sign,
        },
        method
      })
        .then((data) => resolve(data.data))
        .catch((err) => reject(err));
    } else if (method === "POST" || method === "PUT") {
      axios({
        url: `${baseUrl}${url}`,
        data: {
          ...paramsObj,
          sign,
        },
        method,
      })
        .then((data) => resolve(data.data))
        .catch((err) => reject(err));
    }
  });
}
export default request;

/**
 * @Description: 根据返回结果判断是否请求成功
 * @Author: ZQ
 * @Date: 2022-09-16 10:10
 */
export const isRespondSuccess = (result) => result && (result.code === 200);

/**
 * @Description: 根据返回结果判断是否请求失败
 * @Author: ZQ
 * @Date: 2022-09-16 10:10
 */
export const isRespondFail = (result) => !result || (result.code !== 200);

/**
 * @Description: 解析请求返回结果
 * @Author: ZQ
 * @Date: 2022-09-16 10:10
 */
export const parseRespondResult = (result) => {
  if (isRespondSuccess(result)) {
    return true;
  }
  if (isRespondFail(result)) {
    message.error(`${result?.msg}` || '数据加载失败!');
    return false;
  }
  return false;
};


/**
 * @Description: 给html字符串的img标签增加style样式
 * @Author: ZQ
 * @Date: 2022-09-19 12:20
 */
export const imgTagAddStyle = (htmlStr) => {
  //正则匹配不含style="" 或 style='' 的img标签
  const regexImg = new RegExp("(i?)(\<img)(?!(.*?style=['\"](.*)['\"])[^\>]+\>)", "gmi");
  //给不含style="" 或 style='' 的img标签加上style=""
  const addStyleHtml = htmlStr.replace(regexImg, "$2 style=\"\"$3");
  //正则匹配含有style的img标签
  const regex2 = new RegExp("(i?)(\<img.*?style=['\"])([^\>]+\>)", "gmi");
  //在img标签的style里面增加css样式(这里增加的样式：max-width: 900px;margin: 40px auto;)
  const html = addStyleHtml.replace(regex2, "$2 max-width: 900px;margin: 40px auto; $3");
  return html;
}



