import request from '../../utils/request.jsx';


// 根据模块类型查询 banner
export const getBannerData = (params) => {
  return request('/banner/select/module', params);
}

// 查询top数据 首页 5热点
export const getTopData = () => {
  return request('/ps/select/top');
}

// 查询 公司信息
export const getEnterpriseData = () => {
  return request('/enterprise/select/one');
}
