import React, { Component, Fragment } from 'react';
import {btmMap, chinese, english, img, ItemWrapperA, midBox, midBoxInfo, midBoxTit, text, tip, topBan} from './style'
import phone from '../../../statics/img/phone.png';
import address from '../../../statics/img/add.png';
import mail from '../../../statics/img/mail.png';
import phoneCall from '../../../statics/img/phone_h.png';
import map from '../../../statics/img/map.png';

/**
 * @Description: 加入我们 content
 * @Author: ZQ
 * @Date: 2022-09-15 15:30
 */
class JoinWeItem extends Component {
    state = {
      tipChinese: '联系我们',
      tipEnglish: 'Contact Us',
    }


    componentDidMount() {
      window.scrollTo(0, 0);
    }

    componentWillUnmount() {

    }

    render() {
      const { tipChinese, tipEnglish } = this.state;
      const { info, bannerUrl } = this.props;

      const infoList = [
          {
            img: phone,
            sign: '联系电话',
            signText: info?.contactNumber,
            privileges: 4,
          },
          {
            img: address,
            sign: '公司地址',
            signText: info?.enterpriseAddress,
            privileges: 1,
          },
          {
            img: mail,
            sign: '公司邮箱',
            signText: info?.enterpriseEmail,
            privileges: 3,
          },
          {
            img: phoneCall,
            sign: '合作电话',
            signText: info?.cooperativeTelephone,
            privileges: 2,
          },
      ];

      // 样式
      const whoStyle = (it) => {
        const style = (it?.privileges === 1 && { width: '40%' }) ||
                      (it?.privileges === 2 && { width: '25%' }) ||
                      (it?.privileges === 3 && { width: '15%'  }) ||
                      (it?.privileges === 4 && { width: '10%' });
        return { ...style, textAlign: 'center' };
      }

      return (
          <Fragment>
              <ItemWrapperA>
                <img src={bannerUrl} style={topBan} alt=''/>
              </ItemWrapperA>

              <div style={midBox}>
                <div style={midBoxTit}>
                  <div style={chinese}>{tipChinese}</div>
                  <div style={english}>{tipEnglish}</div>
                </div>

                <div style={midBoxInfo}>
                  {
                    infoList?.length ? infoList.map((it, idx) =>
                      <div key={idx} style={whoStyle(it)}>
                        <img style={img} src={it?.img} alt='' />
                        <div style={tip}>{it?.sign}</div>
                        <div style={text}>
                          <div>{it?.signText}</div>
                        </div>
                      </div>
                    ) : ''
                  }
                </div>
              </div>

              {/*<img style={btmMap} src={'http://webfiles.hncdcenter.com/images/map.0eb9c401.png' || map} alt='' />*/}
              <img style={btmMap} src={'https://webfiles.hncdcenter.com/images/map-202403181034312.jpg' || map} alt='' />
          </Fragment>
      );
    }
}

export default JoinWeItem;
