import request from '../../utils/request.jsx';


// 官网分组查询 时间历程
export const getGroupByYear = () => {
  return request('/course/select/groupByYear');
}

// 证书列表
export const getCertificateList = () => {
  return request('/certificate/select/list');
}

