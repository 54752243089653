import React, { Component, Fragment } from 'react';
import {banImg, DescriptionDiv, itBox, ItemWrapperA, itImg, itTime, midBox, padd, TitDiv} from './style'
import {getBannerData} from "../../Main/service";
import {parseRespondResult} from "../../../utils/request";
import {getByIdNews, getNewsList} from "../service";
import {Spin, Pagination} from "antd";
import '../../../style.js';

/**
 * @Description: 新闻动态 content
 * @Author: ZQ
 * @Date: 2022-09-15 14:10
 */
class NewsItem extends Component {
    state = { loading: true,
      pageNum: 1,
      pageSize: 9,
      total: 0,
      bannerUrl: '',
      dataList: [],
    }

    async componentDidMount() {
      window.scrollTo(0, 0);
      await this.getInitData();
    }

    // 初始化数据
    getInitData = async () => {
      this.setState({ loading: true });
      try {
        await this.getInitBanner();
        await this.getPageData(this.state.pageNum);
      }finally {
        this.setState({ loading: false });
      }
    }

    // 获取banner 数据
    getInitBanner = async () => {
      const res = await getBannerData({ moduleType: 4 });
      if(parseRespondResult(res)) {
        this.setState({
          bannerUrl: res?.data?.files[0]?.url,
        });
      }
    }

    // page 改变事件
    getPageData = async (page) => {
      const { pageSize } = this.state;
      const res = await getNewsList({ pageNum: page, pageSize });
      if(parseRespondResult(res)) {
        this.setState({
          pageNum: page,
          dataList: res?.data?.dataList,
          total: res?.data?.totalCount,
        });
      }
    }

    // 分页 change 事件
    onChange = async (page) => {
      this.setState({ loading: true });
      try {
        await this.getPageData(page);
      }finally {
        this.setState({ loading: false });
      }
    }

    // 获取新闻详情
    goNewsDetail = async (id) => {
      const res = await getByIdNews({ newsId: id });
      if(parseRespondResult(res)) {
        if(res?.data?.newsType === 1){
          window.open(res?.data?.newsUrl, '_blank');
        }
        if(res?.data?.newsType === 0){
          this.props.history.push({
            pathname: `/newsDetail`,
            search: id,
          })
        }
      }
    }

    render() {
      const { dataList, loading, total, bannerUrl, pageNum, pageSize } = this.state;

      return (
          <Fragment>
              <Spin spinning={loading}>
                <ItemWrapperA>
                  <img src={bannerUrl} style={banImg} alt=''/>
                </ItemWrapperA>
                <div style={midBox}>
                  {
                    dataList?.length ? dataList.map((it, idx) =>
                      <div style={itBox} key={idx} onClick={() => this.goNewsDetail(it?.newsId)}>
                        <img src={it?.picUrl} alt='' style={itImg} />
                        <div style={padd}>
                          <TitDiv>{it?.newsTitle}</TitDiv>
                          <DescriptionDiv>{it?.newsDescription}</DescriptionDiv>
                          <div style={itTime}>{it?.createTime}</div>
                        </div>
                      </div>
                      ) : ''
                  }
                    {
                      total > 0 &&
                      <div className='paginationBox'>
                        <Pagination
                          className='ant-pagination'
                          pageSize={pageSize}
                          onChange={(page) => this.onChange(page)}
                          showQuickJumper={false}
                          showSizeChanger={false}
                          current={pageNum}
                          total={total}
                        />
                      </div>
                    }
                  </div>
                }
              </Spin>
          </Fragment>
      );
    }
}

export default NewsItem;
