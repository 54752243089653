import React, { Component } from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import IntroduceItem from './components/IntroduceItem.jsx'
import {getBannerData, getEnterpriseData} from "../Main/service";
import {parseRespondResult} from "../../utils/request";
import {Spin, Layout} from "antd";
import {getCertificateList, getGroupByYear} from "./service";

/**
 * @Description: 公司介绍
 * @Author: ZQ
 * @Date: 2022-09-15 10:10
 */
class Index extends Component {

  state = {
    loading: true,
    opacity: 1,
    nowPage: 'introduce',
    // banner 数据
    bannerUrl: '',
    // 公司 信息
    companyInfo: {},
    // 历程 信息
    courseList: [],
    // 证书 数据
    certificateList: [],
  }

  async componentDidMount() {
    await this.getInitData();
  }

  // 初始化数据
  getInitData = async () => {
    this.setState({ loading: true });
    try {
      const res = await Promise.all([
        getBannerData({ moduleType: 2 }),
        getEnterpriseData(),
        getGroupByYear(),
        getCertificateList(),
      ]);
      this.setState({
        bannerUrl: parseRespondResult(res[0]) && res[0]?.data?.files[0]?.url,
        companyInfo: parseRespondResult(res[1]) && res[1]?.data,
        courseList: parseRespondResult(res[2]) && res[2]?.data,
        certificateList: parseRespondResult(res[3]) && res[3]?.data,
      });
    }finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, opacity, nowPage, bannerUrl, companyInfo, courseList, certificateList } = this.state;
    const { history } = this.props;

    return (
      <Layout>
        <Header opacity={opacity} nowPage={nowPage} history={history}/>
        <Spin spinning={loading}>
          <IntroduceItem
            bannerUrl={bannerUrl}
            companyInfo={companyInfo}
            courseList={courseList}
            certificateList={certificateList}
          />
        </Spin>
        <Footer />
      </Layout>
    );
  }
}

export default Index;
