import styled from 'styled-components'

export const ItemWrapperA = styled.div`
    width:100%;
    height:560px;
    margin-top:80px;
`

export const ItemWrapperB = styled.div`
    width:100%;
    margin-top:68px;
`

export const ItemB = styled.div`
    width:1280px;
    margin:0 auto;
    font-family: SourceHanSansCN-Light;
    line-height: 41px;
    color:#000;
    font-size:16px;
    text-align:center;
    letter-spacing: 3.43px;
`

export const ItemWrapperC = styled.div`
    width:100%;
    height:480px;
    margin-top:84px;
`

export const ItemC = styled.div`
  width:1280px;
  height:480px;
  background:#F2F3F5;
  margin:0 auto;
  display:flex;
  flex-direction:row;
  align-items:center;
  font-family: SourceHanSansCN-Light;
  color:#000;
  font-size:16px;
  letter-spacing: 3.43px;
  flex-wrap:nowrap
`

export const ItemCLeft = styled.div`
  font-size: 48px;
  letter-spacing: 6.86px;
  flex:0 0 280px;
  margin:0 59px 0 56px;
`

export const ItemCCenter = styled.div`
  flex:0 0 2px;
  height:223px;
  background:#C4C4C4;
  margin-right:86px;
`

export const ItemWrapperD = styled.div`
  width:100%;
  height: 640px;
  margin-top:68px;
  color: #000,
`

export const ItemD = styled.div`
  width:1280px;
  height:100%;
  margin:0 auto;
`

export const ItemDLeft = styled.div`
  height: 100%;
  padding-top: 10px;
  flex:1;
  color:#000;
  font-size:18px;
  font-family: SourceHanSansCN-Light;
`

export const ItemDRight = styled.div`
  height: 100%;
  flex:1;
  color:#000;
  font-size:18px;
  font-family: SourceHanSansCN-Light;
`

export const ItemWrapperE = styled.div`
  width:100%;
  margin-top:78px;
`

export const ItemE = styled.div`
  width:1280px;
  margin:0 auto;
  font-family: SourceHanSansCN-Light;
  font-size:16px;
  letter-spacing: 2.29px;
  text-align:center;
`

export const ItemWrapperF = styled.div`
  width:100%;
  margin-top:49px;
`

export const ItemF = styled.div`
  width:1280px;
  height:180px;
  margin:0 auto 109px auto;
  display:flex;
  flex-direction:row;
  align-items:center;
`

export const ItemFCard = styled.div`
  flex:1;
  margin: 0 10px 0 20px;
  height: 180px;
  background:#F2F3F5;
  font-size: 16px;
  color: #333333;
  letter-spacing: 2.29px;
  font-family: SourceHanSansCN-Light;
  text-align:center;
  display:flex;
  flex-direction:column;
  align-self:center;
  justify-self:center;
`


export const ItemWrapperG = styled.div`
  width: 100%;
  max-height: 580px;
  font-family: SourceHanSansCN-Light;
  background: #F2F3F5;
  padding-bottom: 40px;
`

export const ItemG = styled.div`
  width:1280px;
  margin:0 auto;
  font-size:16px;
  letter-spacing: 2.29px;
  text-align:center;
`


export const ItemWrapperH = styled.div`
  width:100%;
  margin-top:78px;
`

export const ItemH = styled.div`
  width:1280px;
  margin:0 auto;
  font-family: SourceHanSansCN-Light;
  font-size:16px;
  letter-spacing: 2.29px;
  text-align:center;
`



// style
export const itemBBox = {
  color: '#222',
}

export const itemBBoxTit = {
  fontSize: '48px',
  marginBottom: '29px',
  fontFamily: 'SourceHanSansCN-Light',
  fontWeight: '200',
}

export const itemBDescription = {
  fontSize: '18px',
  color: '#000',
  textAlign: 'justify',
}

export const itemDLeft = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '290px',
  marginBottom: '60px',
  fontSize: '20px',
}

export const itemDLeftTit = {
  fontSize: '48px',
  margin: '10px 0 48px 0',
  fontFamily: 'SourceHanSansCN-Light',
  fontWeight: '200',
}
export const itemDLeftContent = {
  lineHeight: '40px',
  letterSpacing: '2.67px',
  fontSize: '18px',
  color: '#000',
  textAlign: 'justify',
}

export const itemDRight = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '290px',
}

export const leftDImg = {
  width:'100%',
  height:'290px',
  backgroundSize:'635px 290px',
}

export const itemDRightRBox = {
  marginLeft: '72px',
  color: '#000',
}

export const itemDRightRBoxTit = {
  fontSize: '48px',
  margin: '10px 0 48px 0',
  fontFamily: 'SourceHanSansCN-Light',
  fontWeight: '200',
}

export const itemDRightRBoxContent = {
  lineHeight: '40px',
  letterSpacing: '2.67px',
  fontSize: '18px',
  textAlign: 'justify',
}

export const itemE = {
  fontSize: '48px',
  fontFamily: 'SourceHanSansCN-Light',
  fontWeight: '200',
}

export const itTit = {
  fontSize: '32px',
  paddingTop: '58px',
  fontFamily: 'SourceHanSansCN-Light',
  color: '#0052D8',
}

export const itText = {
  paddingTop: '16px',
  fontSize: '18px',
  fontFamily: 'SourceHanSansCN-Light',
  color: '#000',
}

export const btmTit = {
  width: '100%',
  height: '48px',
  fontSize: '48px',
  paddingBottom: '72px',
  color: '#333333',
  letterSpacing: '6.86px',
  fontWeight: '200',
  textAlign: 'center',
  background: '#fff',
}

export const gBox = {
//   display: 'flex',
//   justifyContent: 'space-between',
}

export const itemGBox = {
  width: '360px',
  margin: '0 20px 0 20px',
  padding: '47px 0 80px 0',
  fontFamily: 'SourceHanSansCN-Light',
}

export const itemGTop = {
  height: '32px',
  textAlign: 'left',
  fontSize: '32px',
  fontFamily: 'SourceHanSansCN-Light',
  color: '#000',
  fontWeight: '400',
}

export const line = {
  borderBottom: '1px solid #C6C6C6',
  margin: '24px 0',
}

export const iBox = {
  display: 'flex',
  fontSize: '18px',
  color: '#000',
}

export const iLeft = {
  width: '20%',
  textAlign: 'left',
  // color: '#333333',
  // fontSize: '16px',
  letterSpacing: '0.5px',
  lineHeight: '40px',
}

export const iRight = {
  width: '70%',
  textAlign: 'justify',
  color: '#333333',
  letterSpacing: '0.5px',
  lineHeight: '40px',
}

export const itemH = {
  fontSize: '48px',
  marginTop: '99px',
  textAlign: 'center',
  letterSpacing: '6.86px',
  fontFamily: 'SourceHanSansCN-Light',
  fontWeight: '200',
}

export const imgBox = {
  margin: '67px 0 107px 0',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  flexFlow: 'row wrap',
}

export const itBox = {
  margin: '5px 5px',
  background: '#FFFFFF',
  // boxShadow: '2px 2px 8px 0px rgba(0,0,0,0.2)',
}

export const itImgStyle2 = {
  width: '240px',
  height: '360px',
}

export const itImgStyle1 = {
  width: '240px',
  height: '240px',
}
