import styled from 'styled-components'
import logoAPic from '../../statics/img/head_logo_b.png'
import logoBPic from '../../statics/img/head_logo.png'

export const HeaderWrapper = styled.div`
    position:fixed;
    top:0;
    width:100%;
    height:80px;
    /* background-color:rgba(255,255,255,0); */
    color:#fff;
    z-index:999;
`

export const NavContainer = styled.div`
    width:1280px;
    height:100%;
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    align-items:center;
    margin:0 auto;
`

export const LogoA = styled.div`
    flex:0 0 430px;
    height:49px;
    background:url(${logoAPic}) no-repeat;
    background-size:150px 48px;
`

export const LogoB = styled.div`
    flex:0 0 430px;
    height:49px;
    background:url(${logoBPic}) no-repeat;
    background-size:150px 48px;
    cursor: pointer;
`

export const Nav = styled.div`
    flex:1;
    height:58px;
    box-sizing:border-box;
    display:flex;
    flex-direction:row;
    line-height:58px;
    font-family: SourceHanSansCN-Regular;
    font-size: 22px;
    letter-spacing: 0.15px;
    font-weight: 400;
`

export const NavItem = styled.div`
    flex:1;
    text-align:center;
`

export const NavItemA = styled.a`
  cursor: pointer;
  :hover{
        color:#00B0EF;
    }
    &.active{
        color:#00B0EF;
    }
`
