import React, { Component } from 'react';
import Header from '../../components/header/index';
import Footer from '../../components/footer/index';
import JoinWeItem from "../JoinWe/components/JoinWeItem.jsx";
import {getJoinWeData} from "./service";
import {Spin, Layout} from "antd";
import {parseRespondResult} from "../../utils/request";
import {getBannerData} from "../Main/service";

/**
 * @Description: 加入我们
 * @Author: ZQ
 * @Date: 2022-09-15 15:10
 */
class Index extends Component {

  state = {
    loading: true,
    opacity: 1,
    nowPage:'joinWe',
    info: {},
    bannerUrl: '',
  }

  async componentDidMount() {
    await this.getInitData();
  }

  getInitData = async () => {
    this.setState({ loading: true });
    try {
      const res = await Promise.all([
        getBannerData({ moduleType: 5 }),
        getJoinWeData(),
      ]);
      this.setState({
        bannerUrl: parseRespondResult(res[0]) && res[0]?.data?.files[0]?.url,
        info: parseRespondResult(res[1]) && res[1]?.data,
      })
    }finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, opacity, nowPage, info, bannerUrl } = this.state;
    const { history } = this.props;
    return (
      <Layout style={{ overflowX: 'hidden', }}>
        <Header opacity={opacity} nowPage={nowPage} history={history}/>
        <Spin spinning={loading}>
          <JoinWeItem bannerUrl={bannerUrl} info={info} />
        </Spin>
        <Footer />
      </Layout>
    );
  }
}

export default Index;
