import React, { Component } from 'react';
import { HeaderWrapper, NavContainer,NavItemA,LogoA,LogoB, Nav, NavItem} from './style'
import {headerRouter} from "../../common/constant";

class Header extends Component {

    // 去 首页
    goHomePage = () => {
      localStorage.clear();
      this.props.history.push(`/main`)
    }

    render() {
        const { opacity, nowPage } = this.props;

        const curPage = (nowPage === 'newsDetail' && 'news') || (nowPage === 'productDetail' && 'product') || nowPage;

        const toRouter = (who) => {
          localStorage.clear();
          this.props.history.push(`/${who}`)
        }

        const fontColor = 255 - (255 * opacity);
        const style = {
            backgroundColor: `rgba(255,255,255, ${opacity})`,
            color:`rgb(${fontColor},${fontColor}, ${fontColor})`
        };

        return (
            <HeaderWrapper style={style}>
                <NavContainer>
                {opacity < 0.4 ? <LogoA/> : <LogoB onClick={this.goHomePage.bind(this)}/>}
                  <Nav>
                    {headerRouter.map((it, idx) =>
                      <NavItem key={idx}>
                        <NavItemA
                          onClick={() => toRouter(it?.sign)}
                          className={curPage === it?.sign && 'active'}
                        >
                          {it?.pageTit}
                        </NavItemA>
                      </NavItem>
                    )}
                  </Nav>
                </NavContainer>
            </HeaderWrapper>
        )
    }
}


export default Header
