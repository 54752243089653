import styled from 'styled-components'
import homeItemBPic from '../../../statics/img/home3_bg.png'
import overPic from '../../../statics/img/home3_bg1.png'

export const ItemWrapperB = styled.div`
    width:100%;
    height: 940px;
    background:url(${homeItemBPic}) no-repeat;
    background-size:cover;
`

export const ItemB = styled.div`
    width:1280px;
    margin:0 auto;
    text-align:center;
    font-family: SourceHanSansCN-Light;
    font-size: 56px;
    color: #333333;
    letter-spacing: 8px;
    font-weight: 200;
    padding-top:96px;
`

export const ItemBPic = styled.div`
    width:1280px;
    margin:0 auto;
    display:flex;
    flex-direction:row;
    margin-top:96px;
    align-items:center;
    font-family:SourceHanSansCN-Light;
`

export const ItemMore = styled.div`
    width:1280px;
    margin:0 auto;
    text-align:center;
    font-size: 22px;
    color: #127EFF;
    font-weight: 400;
    font-family: SourceHanSansCN-Normal;
    cursor: pointer;
    letter-spacing: 2.88px;
    padding-top: 84px;
`

export const ItemWrapperC = styled.div`
    width:100%;
    height:871px;
`

export const ItemC = styled.div`
    width:1280px;
    height:100%;
    margin:0 auto;
    display:flex;
    flex-direction:row;
    align-items:center;

`

export const ItemCLeft = styled.div`
    flex:0 0 635px;
    margin-right:72px;
`

export const ItemCRight = styled.div`
    flex:1;
    color:#333;
    height:621px;
    font-size:18px;
    font-family:SourceHanSansCN-Light;
`

export const BgWrapper = styled.div`
    width:100%;
    z-index:1;
`

export const ItemBFourPic1 = styled.div`
    flex:0 0 300px;
    height: 500px;
    margin-right: 26.5px;
    background-position:center;
    font-size: 32px;
    color:#fff;
    text-align:center;
    box-shadow: 2px 2px 8px #999;
`

export const ItemBHover = styled.div`
    width: 300px;
    height: 560px;
    position: relative;
    top: -30px;
    background:url(${overPic}) no-repeat;
    background-size: cover;
    cursor: pointer;
`

export const ItemBHoverTitle = styled.div`
    padding: 57px 16px 20px 16px;
    max-height: 48px;
    overflow: hidden;
    line-height: 32px;
    font-family: SourceHanSansCN-Normal;
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 4.57px;
    text-align: center;
    font-weight: 300;
`

export const ItemBHoverTxt = styled.div`
    padding: 10px 20px 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    font-family: SourceHanSansCN-Light;
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: 2px;
    text-align: justify;
    line-height: 32px;
    font-weight: 200;
`

export const ItemBMore = styled.div`
    width: 180px;
    height: 40px;
    text-align:center;
    font-size: 16px;
    color:#fff;
    letter-spacing: 1px;
    border: 0.5px solid #e5e5e5;
    border-radius:3px;
    line-height: 40px;
    //margin: auto;
    position: absolute;
    bottom: 60px;
    left: calc((297.5px - 180px) / 2);
`

export const CompanyTitDiv = styled.div`
  height: 56px;
  font-family: SourceHanSansCN-Light;
  font-size: 56px;
  color: #333333;
  letter-spacing: 8px;
  font-weight: 200;
`

export const ContentText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 11;
  font-family: SourceHanSansCN-Light;
  text-align: justify;
  font-size: 18px;
  color: #000;
  letter-spacing: 2.67px;
  line-height: 40px;
  font-weight: 200;
  margin: 48px 0 40px 0;
  max-height: 440px;
`




export const itTit = {
  marginTop: '272px',
  fontFamily: 'SourceHanSansCN-Normal',
  fontSize: '32px',
  color: '#FFFFFF',
  letterSpacing: '4.57px',
  fontWeight: '300',
};

export const leftImg = {
  width: '100%',
  height: '621px',
};

export const moreImg = { height: '18px', width: '37px' };

export const more = {
  fontSize: '22px',
  color: '#127EFF',
  fontWeight: '400',
  cursor: 'pointer',
  letterSpacing: '2.88px',
  fontFamily: 'SourceHanSansCN-Normal',
};
