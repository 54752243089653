import { createGlobalStyle } from 'styled-components'

const GrobalStyle = createGlobalStyle
    `
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    .paginationBox{
      width: 100%;
      height: 50px;
      margin-top: 50px;
      position: relative;
    }

    .ant-pagination{
      display: flex;
      justify-content: space-between;
      max-width: 900px;
      min-width: 200px;
      position: absolute;
      right: 20px;
    }

    .ant-pagination-prev{
      margin-right: 10px;
      border-radius: 4px;
    }


    .ant-pagination-disabled{
      opacity: 0.5;
      .ant-pagination-item-link:hover{
        cursor: not-allowed;
      }
    }

    .ant-pagination-next{
      margin-left: 10px;
      border-radius: 4px;
    }

    .ant-pagination-disabled{
      opacity: 0.5;
      .ant-pagination-item-link:hover{
        cursor: not-allowed;
        //color: #1890ff;
      }
    }



    .ant-pagination-item:hover {
      color: #1890ff;
      border: 1px solid #1890ff;
      font-weight: bold;
    }

    .ant-pagination-item-link{
      height: 48px;
      width: 48px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
    }

    .ant-pagination-item-link:hover{
      cursor: pointer;
      border: 1px solid #1890ff;
      color: #1890ff;
    }


    .ant-pagination-item{
      height: 46px;
      width: 46px;
      text-align: center;
      line-height: 48px;
      border: 1px solid #d9d9d9;
      margin: 0 4px 0 8px;
      border-radius: 4px;
    }

    .ant-pagination-item:hover{
      cursor: pointer;
    }

    .ant-pagination-item-active{
      color: #1890ff;
      font-weight: bold;
      border: 1px solid #1890ff;
    }

    .ant-pagination-jump-next > a{
      border: none;
      line-height: 48px;
    }

    .ant-pagination-jump-next > a > .ant-pagination-item-container > .anticon svg{
       display: none;
    }

    .ant-pagination-jump-prev > a{
      border: none;
      line-height: 48px;
    }

    .ant-pagination-jump-prev > a > .ant-pagination-item-container > .anticon svg{
      display: none;
    }

    .ant-pagination-item-ellipsis{
      color: #999999;
    }

    .ant-pagination-next > button{
      background: #fff;
    }

    .ant-pagination-prev > button{
      background: #fff;
    }

    `

export default GrobalStyle



