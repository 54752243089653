import {spaceInLeft, spaceInDown, swashIn, swashOut, spaceInRight, boingInUp} from 'react-magic';
import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
  magic: {
    animationName: spaceInLeft,
    animationDuration: '1.5s'
  },
  rightMagic: {
    animationName: spaceInRight,
    animationDuration: '1.5s'
  },
  magicTxt: {
    animationName: spaceInDown,
    animationDuration: '1.5s'
  },
  magicImg: {
    animationName: spaceInDown,
    animationDuration: '1.5s'
  },

  magicHover: {
    animationName: swashIn,
    animationDuration: '0.3s'
  },
  magicOut: {
    animationName: swashOut,
    animationDuration: '0.3s',
  },
  cardMagic: {
    animationName: boingInUp,
    animationDuration: '2s'
  },
});
