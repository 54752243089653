import React, { Component } from 'react';
import { BgWrapper } from './style';

class VideoOrImgBg extends Component {
  render() {
    const { bannerInfo } = this.props;
    return (
      <BgWrapper>
        {
          (bannerInfo?.bannerType === 1 && <img src={bannerInfo?.url} alt=''/>) ||
          (bannerInfo?.bannerType === 2 && <video
            width="100%"
            muted
            autoPlay
            loop
            x-webkit-airplay='true'
            airplay="allow"
            webkit-playsinline='true'
            playsInline
            src={bannerInfo?.url}
          />)
        }
      </BgWrapper>
    )
  }
}

export default VideoOrImgBg;
