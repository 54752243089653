import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GrobalStyle from './style.js'
import './statics/font/basefont.css'
import 'core-js/es'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

ReactDOM.render(
  <React.StrictMode>
    <App />
    <GrobalStyle></GrobalStyle>
  </React.StrictMode>,
  document.getElementById('root')
);

