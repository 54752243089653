import React, { Component, Fragment } from 'react';
import { css } from 'aphrodite';
import {
  ItemWrapperA,
  ItemWrapperB,
  ItemB,
  ItemWrapperD,
  ItemD,
  ItemDLeft,
  ItemDRight,
  ItemWrapperE,
  ItemE,
  ItemWrapperF,
  ItemF,
  ItemFCard,
  ItemWrapperG,
  ItemG,
  itemBBox,
  itemBBoxTit,
  itemDLeft,
  itemDLeftContent,
  itemDLeftTit,
  itemDRight,
  itemDRightRBox,
  itemDRightRBoxTit,
  itemDRightRBoxContent,
  itemE,
  itTit,
  itText,
  btmTit,
  itemGBox,
  itemGTop,
  line,
  iBox,
  iLeft,
  iRight,
  ItemWrapperH,
  ItemH,
  itemH,
  imgBox,
  itBox,
  gBox,
  leftDImg,
  itImgStyle1,
  itImgStyle2,
  itemBDescription
} from './style'
import { Carousel } from 'antd';
import 'antd/dist/antd.css';
import {styles} from "../../../utils/common";

/**
 * @Description: 公司介绍 content
 * @Author: ZQ
 * @Date: 2022-09-15 10: 30
 */
class IntroduceItem extends Component {
  state={
    dShow: false,
    eShow: false,
    fShow: false,
    gShow: false,
    hShow: false,
    dShowCont: 0,
    eShowCont: 0,
    fShowCont: 0,
    gShowCont: 0,
    hShowCont: 0,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { dShowCont, eShowCont, fShowCont, gShowCont, hShowCont } = this.state;
    if(!dShowCont && !eShowCont && !fShowCont && !gShowCont && !hShowCont) {
      window.addEventListener('scroll', this.scroll);
    }
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.scroll);
  }

  // 滚动条
  scroll = () =>{
    const top = document.documentElement.scrollTop || document.body.scrollTop;
    const dbox=document.getElementById("d");
    const ebox=document.getElementById("e");
    const fbox=document.getElementById("f");
    const gbox=document.getElementById("g");
    const hbox=document.getElementById("h");
    const deviceWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const scale = deviceWidth / 1920;
    if(window.screen.availHeight + top > (dbox.offsetTop - 60) * scale){
      this.setState({
        dShow: true,
        dShowCont: 1,
      });
    }
    if(window.screen.availHeight + top > (ebox.offsetTop - 80) * scale){
      this.setState({
        eShow: true,
        eShowCont: 1,
      });
    }
    if(window.screen.availHeight + top > (fbox.offsetTop - 50) * scale){
      this.setState({
        fShow: true,
        fShowCont: 1,
      });
    }
    if(window.screen.availHeight + top > (gbox.offsetTop + 80) * scale){
      this.setState({
        gShow: true,
        gShowCont: 1,
      });
    }
    if(window.screen.availHeight + top > (hbox.offsetTop - 50) * scale){
      this.setState({
        hShow: true,
        hShowCont: 1,
      });
    }
  }

  render() {
    const { dShow, eShow, fShow, gShow, hShow } = this.state;
    const { bannerUrl, companyInfo, certificateList } = this.props;

    const courseList = [
      {
        courseYear: 2017,
        timeCourses: [
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788481756056285186",
            courseYear: 2017,
            courseMouth: 9,
            event: "公司筹备组成立，河南省政务服务网上线，同年底，河南省网上政务服务能力排名由全国第31名上升至全国第19位！"
          }
        ]
      },
      {
        courseYear: 2018,
        timeCourses: [
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788481962252464130",
            courseYear: 2018,
            courseMouth: 8,
            event: "公司正式注册成立，河南省政务服务APP上线，河南省政务服务迈入移动互联时代！"
          }
        ]
      },
      {
        courseYear: 2019,
        timeCourses: [
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788483101832278017",
            courseYear: 2019,
            courseMouth: 5,
            event: "率先提出“豫事办”品牌的理念，并于当年1月份上线豫事办APP1.0版本！"
          }
        ]
      },
      {
        courseYear: 2020,
        timeCourses: [
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788483183365353473",
            courseYear: 2020,
            courseMouth: 2,
            event: "河南省新冠肺炎健康申报平台上线！"
          }
        ]
      },
      {
        courseYear: 2021,
        timeCourses: [
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788483244627357698",
            courseYear: 2021,
            courseMouth: 3,
            event: "豫信电科引入上市公司南威软件收购杭州数梦工厂持有的云数聚公司全部股权份额，云数聚公司的治理结构和研发能力得到全面的优化！"
          },
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788483299329470466",
            courseYear: 2021,
            courseMouth: 10,
            event: "《国资国企在线监管平台》获得2021年鲲鹏应用创新大赛三等奖！"
          }
        ]
      },
      {
        courseYear: 2022,
        timeCourses: [
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788483384645808129",
            courseYear: 2022,
            courseMouth: 3,
            event: "《实验室信息系统V1.0》获得省工信厅颁发的首版次软件证书！"
          },
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788483574949769218",
            courseYear: 2022,
            courseMouth: 9,
            event: "《基于鲲鹏的智慧养老服务平台》获得2022年鲲鹏应用创新大赛三等奖！"
          },
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788483629953871874",
            courseYear: 2022,
            courseMouth: 12,
            event: "通过2022年河南省创新型中小企业评审！"
          }
        ]
      },
      {
        courseYear: 2023,
        timeCourses: [
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788483678251282433",
            courseYear: 2023,
            courseMouth: 8,
            event: "《国资国企在线监管平台V1.0》获得省工信厅颁发的首版次软件证书！"
          },
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788483727869898754",
            courseYear: 2023,
            courseMouth: 9,
            event: "《数据中心系统》获得2023年鲲鹏应用创新大赛三等奖！"
          },
          {
            createBy: "admin",
            createTime: "2024-05-09",
            updateBy: "admin",
            updateTime: "2024-05-09",
            courseId: "1788483783486369793",
            courseYear: 2023,
            courseMouth: 12,
            event: "投资监管、企业投管两款产品荣获国务院国资委智能监管业务模型创新活动优秀应用奖，跻身全国百强！"
          }
        ]
      }
    ];

    // 价值观 4  card  数据
    const coreValueList = [
      { text: companyInfo?.coreValue1st },
      { text: companyInfo?.coreValue2nd },
      { text: companyInfo?.coreValue3rd },
      { text: companyInfo?.coreValue4th },
    ]

    // 转数据格式
    const filterList = (arr) => {
      let newArr = [];
      if(arr && arr.length > 0) {
        arr.forEach((it) => {
          const urlList = it?.certificateUrl?.split(',');
          urlList.forEach((i) => {
            const obj = {
              url: i,
              type: it?.certificateType,
            };
            newArr.push(obj)
          })
        })
      }
      return newArr;
    }

    const groups = [];
    for (let i = 0; i < courseList.length; i += 4) {
      groups.push(courseList.slice(i, i + 4));
    }

    return (
      <Fragment>
        <div>
          <ItemWrapperA>
            <img src={bannerUrl} style={{width:'100%', height: '100%'}} alt=''/>
          </ItemWrapperA>

          <ItemWrapperB>
            <ItemB>
              <div style={itemBBox}>
                <div style={itemBBoxTit}>公司简介</div>
                <div style={itemBDescription}>{ companyInfo?.description }</div>
              </div>
            </ItemB>
          </ItemWrapperB>

          <ItemWrapperD id='d'>
            <ItemD className={dShow && css(styles.magic)}>
              <div style={itemDLeft}>
                <ItemDLeft>
                  <div style={itemDLeftTit}>使命</div>
                  <div style={itemDLeftContent}>{ companyInfo?.mission }</div>
                </ItemDLeft>
                <ItemDRight>
                  <img style={leftDImg} src={companyInfo?.missionFile} alt='' />
                </ItemDRight>
              </div>

              <div style={itemDRight} >
                <ItemDLeft>
                  <img style={leftDImg} src={companyInfo?.visionFile} alt='' />
                </ItemDLeft>
                <ItemDRight>
                  <div style={itemDRightRBox}>
                    <div style={itemDRightRBoxTit}>愿景</div>
                    <div style={itemDRightRBoxContent}>{ companyInfo?.vision }</div>
                  </div>
                </ItemDRight>
              </div>
            </ItemD>
          </ItemWrapperD>

          <ItemWrapperE id='e'>
            <ItemE className={eShow && css(styles.rightMagic)}>
              <span style={itemE}>价值观</span><br /><br /><br /><br />
              <span style={{ fontSize: '18px',
                color: '#000', lineHeight: '41px', textAlign: 'justify', }}>{companyInfo?.coreValueDescription}</span>
            </ItemE>
          </ItemWrapperE>

          <ItemWrapperF>
            <ItemF id='f'>
              {
                coreValueList?.length ? coreValueList.map((it, idx) =>
                  <ItemFCard className={fShow && css(styles.cardMagic)} key={idx}>
                    {
                      idx === 3 ?
                      <div style={itTit}>共赢</div> : <div style={itTit}>{it?.text?.substring(0, 2)}</div>
                    }
                    <div style={itText}>{ it?.text }</div>
                  </ItemFCard>
                ) : ''
              }
            </ItemF>
          </ItemWrapperF>

          <ItemWrapperG id='g' className={gShow && css(styles.magic)}>
            <div style={btmTit}>历程</div>

            <ItemG style={gBox}>
              {
                <Carousel autoplay autoplaySpeed={3000} dots={false} style={{ width: '1280px' }}>
                  {
                    groups.map((group, index) => (
                      <div key={index}>
                        {
                          group?.length ? group.map((it, idx) =>
                              <div key={idx} style={itemGBox}>
                                <div style={itemGTop}>{it?.courseYear}</div>
                                <div style={line}/>
                                <div>
                                  {
                                    it?.timeCourses?.length > 0 ? it?.timeCourses.map((i, d) =>
                                        <div key={d} style={iBox}>
                                          <div style={iLeft}>{i?.courseMouth}月</div>
                                          <div style={iRight}>{i?.event}</div>
                                        </div>
                                    ) : ''
                                  }
                                </div>
                              </div>
                          ) : ''
                        }
                      </div>
                    ))
                  }
                </Carousel>
              }
            </ItemG>
          </ItemWrapperG>

          <ItemWrapperH id='h'>
            <ItemH className={hShow && css(styles.rightMagic)}>
              <div style={itemH}>资质证书</div>
              <div style={imgBox}>
                {
                  filterList(certificateList)?.map((it, idx) =>
                    <div key={idx} style={itBox}>
                      <img style={(it?.type === 1 && itImgStyle1) || (it?.type === 2 && itImgStyle2)} src={it?.url} alt=''/>
                    </div>
                  ) || ''
                }
              </div>
            </ItemH>
          </ItemWrapperH>
        </div>
      </Fragment>
    );
  }
}

export default IntroduceItem;
