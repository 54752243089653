import React, { Component } from 'react';
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import {imgTagAddStyle, parseRespondResult} from "../../../utils/request";
import {getByIdPS} from "../service";
import {
  content,
  contentTit,
  description,
  detailBox,
  detailContentBox,
  detailTitBox,
  oneTit,
  pointer,
  tit,
  twoTit
} from "./style";
import {Spin} from "antd";

/**
 * @Description: 产品&解决方案 详情
 * @Author: ZQ
 * @Date: 2022-09-16 11:10
 */
class ProductDetail extends Component {
  state = {
    opacity: 1,
    nowPage:'productDetail',
    detailInfo: {},
    psContent: '',
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getInitData();
  }

  componentWillUnmount() {
  }

  getInitData = async () => {
    this.setState({ loading: true });
    const id = this.props?.location?.search?.replace('?', '');
    try {
      const res = await getByIdPS({ psId: id });
      if(parseRespondResult(res)) {
        this.setState({
          detailInfo: res?.data,
          psContent: imgTagAddStyle(res?.data?.psContent),
        })
      }
    }finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { detailInfo, opacity, nowPage, loading, psContent } = this.state;
    const { history } = this.props;

    return (
      <div style={{ overflowX: 'hidden', }}>
        <Header opacity={opacity} nowPage={nowPage} history={history}/>
        <Spin spinning={loading}>
          <div style={detailBox}>
            <div style={detailTitBox}>
              <div style={tit}>
                <span
                  style={pointer}
                  onClick={() => history.push('/product')}
                >
                  产品&解决方案
                </span>
                  &nbsp;&nbsp;>&nbsp;&nbsp;
                  <span>{detailInfo?.psName}</span>
              </div>
            </div>

            <div style={detailContentBox}>
              <div style={contentTit}>{detailInfo?.psName}</div>
              {/*<div style={oneTit}>产品简介</div>*/}
              {/*<div style={description}>{detailInfo?.psDescription}</div>*/}
              {/*<div style={twoTit}>产品介绍</div>*/}
              <div style={content}
                   dangerouslySetInnerHTML={{ __html: psContent }}
              />
            </div>
          </div>
        </Spin>
        <Footer />
      </div>
    );
  }
}

export default ProductDetail;
