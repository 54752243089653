import React, { Component } from 'react';
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import {getByIdNews} from "../service";
import {imgTagAddStyle, parseRespondResult} from "../../../utils/request";
import {backTip, backTipBox, box, cont, contBox, contTime, contTit, description, diver, pointer, twoTit} from "./style";

/**
 * @Description: 产品&解决方案 详情
 * @Author: ZQ
 * @Date: 2022-09-16 09:10
 */
class ProductDetail extends Component {
  state = {
    loading: true,
    opacity: 1,
    nowPage:'newsDetail',
    detailInfo: {},
    newsContent: '',
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getInitData();
  }

  getInitData = async () => {
    this.setState({ loading: true });
    const id = this.props?.location?.search?.replace('?', '');
    try {
      const res = await getByIdNews({ newsId: id });
      if(parseRespondResult(res)) {
        this.setState({
          detailInfo: res?.data,
          newsContent: imgTagAddStyle(res?.data?.newsContent),
        })
      }
    }finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { opacity, nowPage, detailInfo, newsContent } = this.state;
    const { history } = this.props;

    return (
      <div style={{ overflowX: 'hidden', }}>
        <Header opacity={opacity} nowPage={nowPage} history={history}/>
        <div style={box}>
          <div style={backTipBox}>
            <div style={backTip}>
              <span style={pointer} onClick={() => history.push('/news')}>新闻动态</span>
              &nbsp;&nbsp;>&nbsp;&nbsp;
              <span>新闻详情</span>
            </div>
          </div>

          <div style={contBox}>
            <div style={contTit}>{detailInfo?.newsTitle}</div>
            <div style={contTime}>{detailInfo?.createTime}</div>
            <div style={diver} />
            <div style={description}>{detailInfo?.newsDescription}</div>
            <div style={twoTit}>产品介绍</div>
            <div style={cont} dangerouslySetInnerHTML={{__html: newsContent}}/>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ProductDetail;
