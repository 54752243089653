import Main from '../pages/Main/index.jsx';
import Introduce from '../pages/Introduce/index.jsx';
import Product from '../pages/Product/index.jsx';
import ProductDetail from '../pages/Product/components/ProductDetail.jsx';
import News from '../pages/News/index.jsx';
import NewsDetail from '../pages/News/components/NewsDetail.jsx';
import JoinWe from '../pages/JoinWe/index.jsx';


const routes = [
    {
        path: '/',
        component: Main,
        exact: true
    },
    {
        path: '/main',
        component: Main,
        exact: true
    },
    {
        path:'/introduce',
        component: Introduce,
    },
    {
      path:'/product',
      component: Product,
    },
    {
      path:'/productDetail',
      component: ProductDetail,
    },
    {
        path:'/news',
        component: News,
    },
    {
        path:'/newsDetail',
        component: NewsDetail,
    },
    {
        path:'/joinWe',
        component: JoinWe,
    }
]
export default routes
